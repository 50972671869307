module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"D:\\Bang WEB\\apartmentserik.com/src/intl","languages":["hr","en","it","de"],"defaultLanguage":"hr","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Apartaments Erik","short_name":"Apartaments Erik","start_url":"/","background_color":"#f1fcff","theme_color":"#fca311","display":"standalone","icon":"static/imgs/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"11878ecca407c5504bf24c44eca47b44"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
